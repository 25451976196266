import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

// import resume from '../assets/CV/Sagar-Satam-CV2019.pdf'

import about1 from '../assets/images/aboutthings/things1.jpg'
import about2 from '../assets/images/aboutthings/things2.png'
import about3 from '../assets/images/aboutthings/things3.png'
import about4 from '../assets/images/aboutthings/things4.png'
import things_logo from '../assets/images/aboutthings/things-logo.jpg'

// import things_logo from '../../../assets/images/projects/service/tech/things-logo.jpg'

// import about8 from '../assets/images/about/about8.jpg'
// import awardsgroup from '../assets/images/awardsgroup.png'
// import mediumlogo from '../assets/images/mediumlogo.png'


// import award1 from '../assets/images/awards/award1.png'
// import award2 from '../assets/images/awards/award2.png'
// import award3 from '../assets/images/awards/award3.png'
// import award4 from '../assets/images/awards/award4.png'
// import award5 from '../assets/images/awards/award5.png'
// import award6 from '../assets/images/awards/award6.png'
// import award7 from '../assets/images/awards/award7.png'
// import award8 from '../assets/images/awards/award8.png'

const Aboutme = props => (
  <Layout>
    <Helmet
      title="About Me | Portfolio Sagar Satam"
      meta={[
        { name: 'Design', content: 'Portfolio' },
        { name: 'Design', content: 'product design, designer' },
      ]}
    />

    <div id="main" className="alt">
      <section id="one" className="title-block inner">
        <div className="hp-featured">
          <h1 className="hp-featured__title">
            About THINGS
            <br />
            <br />
          </h1>
          <div className="hp-featured__content">
          <br />
          <div className="client-image">
          <img className="span-4" src={things_logo} alt="things logo" />
          </div>
          <br />
            <p className="hp-featured__text">
            THINGS is a European design and innovation agency for the Internet of Things (IoT). 
            We provided customizable design services tailored to enhance Businesses by 
            using the right agile & lean ingredients.
            </p>
          </div>
        </div>
      </section>

      {/* <section id="content" className="content-wrapper inner">
        <div className="content-body">
          <div id="aboutme-banner" className="two-sidebyside">
            <div className="img right">
              <img src={about1} alt="" />
            </div>
            
          </div>
        </div>
      </section> */}

      <div className="project-wrapper">
      <div className="project-content">
      <section className="process-section">
      <div className="img right">
              <img src={about1} alt="" />
      </div>
      <br />
      <br />
      <div className="img left">
              <img src={about3} alt="" />
        </div>
        <p className="full-textlarge">
        At THINGS I played the role of a UX/UI & Service designer —
        🔍 the researcher,
        🦮 the facilitator,
        and ✍️ the experience designer.
        </p>
        <div className="img right">
              <img src={about2} alt="" />
        </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
      <div className="process">           
            <div className="process-left">
              <h4>I was involved in working closely on 4 key projects —</h4>
            </div>
            <div className="img left">
              <img src={about4} alt="" />
            </div>
      </div>
      </section>
      </div>
      </div>


      

      <section id="footertopcta" className="inner">
        <p className="text arrow-front">
        <a href="/work/service-design-postop">check out the project Post Op</a>
        </p>
        {/* <p className="text arrow-front">
        <a href="/what-offer">now that you know a bit about me, 
        find out what i can offer you</a>
        </p> */}
      </section>
    </div>
  </Layout>
)

export default Aboutme
